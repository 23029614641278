export const PAGE_NAMES = {
  ABOUT: 'About',
  HOME: 'Home',
  CONTACT: 'Contact',
  BLOGS: 'Blogs',
  PROJECTS: 'Projects',
  PROMOTIONS: 'Promotions',
  PRIVACY: 'Privacy',
  404: '404'
}
